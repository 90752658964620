var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            { staticStyle: { background: "#ffffff" } },
            [
              _c("head-layout", {
                attrs: {
                  "head-title": _vm.headTiTle,
                  "head-btn-options":
                    _vm.type == "view"
                      ? _vm.headBtnViewsOptions
                      : _vm.headBtnOptions,
                },
                on: {
                  "head-save": _vm.headSave,
                  "head-add": _vm.headAdd,
                  "head-cancel": _vm.headCancel,
                },
              }),
              _c(
                "div",
                { staticStyle: { padding: "12px" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        "label-width": "100px",
                        rules: _vm.rules,
                        disabled: _vm.type == "view",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "申请编号",
                                    prop: "recptCode",
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: { maxlength: "64" },
                                      model: {
                                        value: _vm.form.recptCode,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "recptCode", $$v)
                                        },
                                        expression: "form.recptCode",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "append" },
                                          slot: "append",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    自动生成\n                    "
                                          ),
                                          _c("el-switch", {
                                            on: { change: _vm.codeAutoClick },
                                            model: {
                                              value: _vm.codeAuto,
                                              callback: function ($$v) {
                                                _vm.codeAuto = $$v
                                              },
                                              expression: "codeAuto",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "危化品名称",
                                    prop: "itemName",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择",
                                        "value-key": "id",
                                        clearable: "",
                                      },
                                      on: { change: _vm.itemsChange },
                                      model: {
                                        value: _vm.form.item,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "item", $$v)
                                        },
                                        expression: "form.item",
                                      },
                                    },
                                    _vm._l(_vm.tableData, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.itemName,
                                          value: item,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "规格型号", prop: "spec" } },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.form.spec,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "spec", $$v)
                                      },
                                      expression: "form.spec",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "批次号", prop: "batchCode" },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      on: { change: _vm.batchCodeClick },
                                      model: {
                                        value: _vm.form.batchCode,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "batchCode", $$v)
                                        },
                                        expression: "form.batchCode",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "append" },
                                          slot: "append",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    自动生成\n                    "
                                          ),
                                          _c("el-switch", {
                                            on: { change: _vm.batchAutoClick },
                                            model: {
                                              value: _vm.batchCodeAuto,
                                              callback: function ($$v) {
                                                _vm.batchCodeAuto = $$v
                                              },
                                              expression: "batchCodeAuto",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "采购入库量",
                                    prop: "quantity",
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      min: 1,
                                      precision: 2,
                                      label: "采购入库量",
                                    },
                                    on: { input: _vm.handleInput },
                                    model: {
                                      value: _vm.form.quantity,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "quantity", $$v)
                                      },
                                      expression: "form.quantity",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "单位",
                                    prop: "unitOfMeasure",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.form.unitOfMeasure,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "unitOfMeasure", $$v)
                                      },
                                      expression: "form.unitOfMeasure",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "储存位置",
                                    prop: "warehouseId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.form.warehouseId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "warehouseId", $$v)
                                        },
                                        expression: "form.warehouseId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.defaultWarehouseOptions,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.warehosueName,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "入库时间",
                                    prop: "recptDate",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "datetime",
                                      placeholder: "选择日期",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                    },
                                    model: {
                                      value: _vm.form.recptDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "recptDate", $$v)
                                      },
                                      expression: "form.recptDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "有效期",
                                    prop: "expireDate",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      "picker-options": _vm.pickerOptions,
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.form.expireDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "expireDate", $$v)
                                      },
                                      expression: "form.expireDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "备注" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      maxlength: 500,
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.form.remark,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "remark", $$v)
                                      },
                                      expression: "form.remark",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-container",
        [
          _c(
            "el-main",
            { staticStyle: { background: "#ffffff" } },
            [
              _c("head-layout", {
                attrs: { "head-title": "供应商信息", "head-btn-options": [] },
                on: {
                  "head-save": _vm.headSave,
                  "head-add": _vm.headAdd,
                  "head-cancel": _vm.headCancel,
                },
              }),
              _c(
                "div",
                { staticStyle: { padding: "12px" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "gysForm",
                      attrs: {
                        model: _vm.form,
                        "label-width": "100px",
                        disabled: _vm.type == "view",
                        rules: _vm.rules,
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "供应商名称",
                                    prop: "dmItemVendor.vendorName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: 255,
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.form.dmItemVendor.vendorName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.dmItemVendor,
                                          "vendorName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.dmItemVendor.vendorName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "生产许可证号" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.form.dmItemVendor.license,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.dmItemVendor,
                                          "license",
                                          $$v
                                        )
                                      },
                                      expression: "form.dmItemVendor.license",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "技术说明书" } },
                                [
                                  _vm.type != "view"
                                    ? _c(
                                        "el-upload",
                                        {
                                          staticClass: "upload-demo",
                                          attrs: {
                                            action:
                                              "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km",
                                            "on-preview": _vm.handlePreview,
                                            "on-remove": _vm.handleRemove,
                                            "on-success": _vm.handleDocSuccess,
                                            "before-remove": _vm.beforeRemove,
                                            limit: 1,
                                            "file-list": _vm.docFilelist,
                                            accept:
                                              ".rar,.zip,.doc,.docx,.pdf,.png,.jpg,.jpeg",
                                            "on-exceed": _vm.handleExceed,
                                            "show-file-list": true,
                                            headers: _vm.headers,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                              },
                                            },
                                            [_vm._v("点击上传")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.type == "view"
                                    ? _c(
                                        "el-link",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.showFile(
                                                _vm.form.dmItemVendor.docUrl
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.form.dmItemVendor.docName
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "产品合格证" } },
                                [
                                  _vm.type != "view"
                                    ? _c(
                                        "el-upload",
                                        {
                                          staticClass: "upload-demo",
                                          attrs: {
                                            action:
                                              "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km",
                                            "show-file-list": true,
                                            "on-preview": _vm.handlePreview,
                                            "on-success": _vm.handleQcSuccess,
                                            "on-remove": _vm.handleRemove,
                                            "before-remove": _vm.beforeRemove,
                                            "file-list": _vm.qcFileList,
                                            limit: 1,
                                            accept:
                                              ".rar,.zip,.doc,.docx,.pdf,.png,.jpg,.jpeg",
                                            "on-exceed": _vm.handleExceed,
                                            headers: _vm.headers,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                              },
                                            },
                                            [_vm._v("点击上传")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.type == "view"
                                    ? _c(
                                        "el-link",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.showFile(
                                                _vm.form.dmItemVendor.qcUrl
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.form.dmItemVendor.qcName)
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "危化品相关文件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }