<template>
  <div class="content">
    <el-container>
      <el-main style="background: #ffffff">
        <head-layout
          :head-title="headTiTle"
          :head-btn-options="
            type == 'view' ? headBtnViewsOptions : headBtnOptions
          "
          @head-save="headSave"
          @head-add="headAdd"
          @head-cancel="headCancel"
        ></head-layout>
        <div style="padding: 12px">
          <el-form
            :model="form"
            label-width="100px"
            ref="form"
            :rules="rules"
            :disabled="type == 'view'"
          >
            <el-row>
              <el-col :span="8">
                <el-form-item label="申请编号" prop="recptCode">
                  <el-input v-model="form.recptCode" maxlength="64">
                    <span slot="append">
                      自动生成
                      <el-switch
                        v-model="codeAuto"
                        @change="codeAutoClick"
                      ></el-switch>
                    </span>
                  </el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="3">
                <el-form-item label="自动生成">
                  <el-switch
                    v-model="codeAuto"
                    @change="codeAutoClick"
                  ></el-switch>
                </el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item label="危化品名称" prop="itemName">
                  <!-- <el-input v-model="form.itemName"></el-input> -->
                  <el-select
                    v-model="form.item"
                    filterable
                    placeholder="请选择"
                    value-key="id"
                    @change="itemsChange"
                    clearable
                  >
                    <el-option
                      v-for="item in tableData"
                      :key="item.id"
                      :label="item.itemName"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="规格型号" prop="spec">
                  <el-input v-model="form.spec" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="批次号" prop="batchCode">
                  <el-input v-model="form.batchCode" @change="batchCodeClick">
                    <span slot="append">
                      自动生成
                      <el-switch
                        v-model="batchCodeAuto"
                        @change="batchAutoClick"
                      ></el-switch>
                    </span>
                  </el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="3">
                <el-form-item label="自动生成">
                  <el-switch
                    v-model="batchCodeAuto"
                    @change="batchAutoClick"
                  ></el-switch>
                </el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item label="采购入库量" prop="quantity">
                  <el-input-number
                    v-model="form.quantity"
                    :min="1"
                    :precision="2"
                    @input="handleInput"
                    label="采购入库量"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="单位" prop="unitOfMeasure">
                  <el-input v-model="form.unitOfMeasure" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="储存位置" prop="warehouseId">
                  <el-select
                    v-model="form.warehouseId"
                    placeholder="请选择"
                    clearable
                  >
                    <el-option
                      v-for="item in defaultWarehouseOptions"
                      :key="item.id"
                      :label="item.warehosueName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="3">
                <el-form-item label=""> </el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item label="入库时间" prop="recptDate">
                  <el-date-picker
                    type="datetime"
                    placeholder="选择日期"
                    v-model="form.recptDate"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="有效期" prop="expireDate">
                  <el-date-picker
                    type="date"
                    placeholder="选择日期"
                    v-model="form.expireDate"
                    :picker-options="pickerOptions"
                    style="width: 100%"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注">
                  <el-input
                    type="textarea"
                    v-model="form.remark"
                    :maxlength="500"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-main>
    </el-container>
    <el-container>
      <el-main style="background: #ffffff">
        <head-layout
          head-title="供应商信息"
          :head-btn-options="[]"
          @head-save="headSave"
          @head-add="headAdd"
          @head-cancel="headCancel"
        ></head-layout>
        <div style="padding: 12px">
          <el-form
            :model="form"
            label-width="100px"
            :disabled="type == 'view'"
            ref="gysForm"
            :rules="rules"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="供应商名称" prop="dmItemVendor.vendorName">
                  <el-input
                    v-model="form.dmItemVendor.vendorName"
                    :maxlength="255"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="生产许可证号">
                  <el-input v-model="form.dmItemVendor.license"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="技术说明书">
                  <el-upload
                    v-if="type != 'view'"
                    class="upload-demo"
                    action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :on-success="handleDocSuccess"
                    :before-remove="beforeRemove"
                    :limit="1"
                    :file-list="docFilelist"
                    accept=".rar,.zip,.doc,.docx,.pdf,.png,.jpg,.jpeg"
                    :on-exceed="handleExceed"
                    :show-file-list="true"
                    :headers="headers"
                  >
                    <el-button size="small" type="primary">点击上传</el-button>
                  </el-upload>
                  <el-link
                    v-if="type == 'view'"
                    @click="showFile(form.dmItemVendor.docUrl)"
                    >{{ form.dmItemVendor.docName }}</el-link
                  >
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产品合格证">
                  <el-upload
                    v-if="type != 'view'"
                    class="upload-demo"
                    action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km"
                    :show-file-list="true"
                    :on-preview="handlePreview"
                    :on-success="handleQcSuccess"
                    :on-remove="handleRemove"
                    :before-remove="beforeRemove"
                    :file-list="qcFileList"
                    :limit="1"
                    accept=".rar,.zip,.doc,.docx,.pdf,.png,.jpg,.jpeg"
                    :on-exceed="handleExceed"
                    :headers="headers"
                  >
                    <el-button size="small" type="primary">点击上传</el-button>
                  </el-upload>
                  <el-link
                    v-if="type == 'view'"
                    @click="showFile(form.dmItemVendor.qcUrl)"
                    >{{ form.dmItemVendor.qcName }}</el-link
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-main>
    </el-container>
    <!-- 选择危险品名称 -->
    <!-- <el-dialog
      title="请选择危险品"
      v-dialogDrag
      :visible.sync="listDialog"
      width="80%"
    >
      <div style="width: 100%; height: 520px">
        <grid-layout
          ref="gridLayOut"
          :table-options="option"
          :data-total="page.total"
          :table-data="tableData"
          :table-loading="tableLoading"
          @gird-handle-select-click="selectionChange"
          :page="page"
          @page-current-change="getList"
          @page-size-change="getList"
        >
          <template #customBtn="{ row }">
            <el-button
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="selectionRowChange(row)"
              >选择</el-button
            >
          </template>
        </grid-layout>
      </div>
      <div class="avue-dialog__footer">
        <el-button @click="listDialog = false">取 消</el-button>
        <el-button @click="handleselectionChange" type="primary"
          >确 定</el-button
        >
      </div>
    </el-dialog> -->
    <el-dialog
      v-dialog-drag
      title="危化品相关文件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getcgCode,
  getcgBatchCode,
  getcwAllList,
  addcgList,
  getList,
  listcgDetail,
} from "@/api/hazardousChemicals/index.js";
import website from "@/config/website";
import { mapGetters } from "vuex";
import { getToken } from "@/util/auth";
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return new Date().getTime() - 24 * 60 * 60 * 1000 >= time.getTime();
        },
      },
      qcFileList: [],
      docFileList: [],
      showFileDialog: false,
      fileUrl: "",
      headTiTle: "采购申请",
      listDialog: false,
      tableData: [],
      tableLoading: false,
      codeAuto: true,
      batchCodeAuto: true,
      form: {
        dmItemVendor: {},
        recptDate: new Date(),
        item: {},
        warehouseId: "",
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      option: [],
      headBtnOptions: [
        {
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        },
        {
          label: "提交",
          emit: "head-add",
          type: "button",
          btnOptType: "submit",
        },
        {
          label: "取消",
          emit: "head-cancel",
          type: "button",
          btnOptType: "cancel",
        },
      ],
      headBtnViewsOptions: [
        {
          label: "取消",
          emit: "head-cancel",
          type: "button",
          btnOptType: "cancel",
        },
      ],
      rules: {
        recptCode: [
          { required: true, message: "请输入采购编号", trigger: "blur" },
        ],
        itemName: [
          { required: true, message: "请选择危化品名称", trigger: "change" },
        ],
        batchCode: [
          { required: true, message: "请输入批次号", trigger: "change" },
        ],
        warehouseId: [
          { required: true, message: "请选择存储位置", trigger: "change" },
        ],
        quantity: [
          { required: true, message: "请输入采购入库量", trigger: "change" },
        ],
        recptDate: [
          { required: true, message: "请选择入库时间", trigger: "change" },
        ],
        expireDate: [
          { required: true, message: "请选择有效期", trigger: "change" },
        ],
        "dmItemVendor.vendorName": [
          { required: true, message: "请填写供应商名称", trigger: "blur" },
        ],
      },
      dmItemInfo: {},
      itemId: "",
      batchCode: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headers: function () {
      return { "Sinoma-Auth": getToken() };
    },
  },
  created() {
    this.type = this.$route.query.type || "add";
    this.id = this.$route.query.id || "";
    this.itemId = this.$route.query.itemId || "";
    this.batchCode = this.$route.query.batchCode || "";
    if (this.type == "add") {
      this.form.recptDate = this.formatDate(new Date());
      this.getcgCode();
      this.getcgBatchCode();
    } else {
      if (this.id) {
        this.getDetail({ id: this.id });
      } else {
        this.getDetail({ itemId: this.itemId, batchCode: this.batchCode });
      }
    }
    this.getcwAllList();
    this.getList();
  },
  methods: {
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    formatDate(date) {
      function padZero(num) {
        return num < 10 ? "0" + num : num;
      }

      var year = date.getFullYear();
      var month = padZero(date.getMonth() + 1);
      var day = padZero(date.getDate());
      var hours = padZero(date.getHours());
      var minutes = padZero(date.getMinutes());
      var seconds = padZero(date.getSeconds());

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    handleInput(value) {
      this.form.quantity = Number(value.toFixed(2));
      if (this.form.quantity == 0) {
        this.form.quantity = 1;
        this.$message({
          message: "采购量必须大于0",
          type: "warning",
        });
      }
    },
    itemsChange(val) {
      this.form.spec = val.spec;
      this.form.unitOfMeasure = val.unitOfMeasure;
      this.form.itemId = val.itemId;
      this.form.itemName = val.itemName;
      this.form.warehouseId = val.defaultWarehouseId;
    },
    codeAutoClick() {
      if (this.codeAuto) {
        this.getcgCode();
      } else {
        this.form.recptCode = "";
      }
    },
    batchAutoClick() {
      if (this.codeAuto) {
        this.getcgBatchCode();
      } else {
        this.form.batchCode = "";
      }
    },
    getDetail(parmas) {
      listcgDetail(parmas).then((res) => {
        this.form = res.data.data;
        if (this.form.itemId) {
          this.form.item = {
            id: this.form.itemId,
            itemName: this.form.itemName,
            itemCode: this.form.itemCode,
          };
        }

        console.log(this.form);
        if (this.form.dmItemVendor.docUrl) {
          this.docFilelist = [
            {
              url: this.form.dmItemVendor.docUrl,
              name: this.form.dmItemVendor.docName,
            },
          ];
        }
        if (this.form.dmItemVendor.qcUrl) {
          this.qcFileList = [
            {
              url: this.form.dmItemVendor.qcUrl,
              name: this.form.dmItemVendor.qcName,
            },
          ];
        }
      });
    },
    getcwAllList() {
      let parmas = {
        organizationId: this.userInfo.dept_id,
      };
      getcwAllList(parmas)
        .then((res) => {
          this.defaultWarehouseOptions = res.data.data;
        })
        .catch((error) => {});
    },
    // 获取危险品种类列表
    getList() {
      this.tableLoading = true;
      // this.page = page;
      let parmas = {
        organizationId: this.userInfo.dept_id,
        // size: this.page.size,
        // current: this.page.currentPage,
      };
      getList(parmas).then((res) => {
        this.tableData = res.data.data;
        if (this.type == "add") {
          if (this.itemId) {
            this.form.item = this.tableData.find((item) => {
              return item.id == this.itemId;
            });
            this.form.itemName = this.form.item.itemName;
            this.form.itemId = this.form.item.id;
            this.form.spec = this.form.item.spec;
            this.form.warehouseId = this.form.item.defaultWarehouseId;
            this.form.unitOfMeasure = this.form.item.unitOfMeasure;
          }
        }
        this.tableLoading = false;

        console.log(res);
      });
    },
    // 取消
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headSave() {
      this.addList("PREPARE");
    },
    headAdd() {
      this.addList("FINISHED");
    },
    addList(recptStatus) {
      if (this.form.item) {
        this.form.itemName = this.form.item.itemName;
        this.form.itemId = this.form.item.id;
        this.form.itemCode = this.form.item.itemCode;
        this.form.dmItemVendor.itemName = this.form.item.itemName;
        this.form.dmItemVendor.itemId = this.form.item.id;
        this.form.dmItemVendor.itemCode = this.form.item.itemCode;
        delete this.form.item;
      }
      let parmas = {
        organizationId: this.userInfo.dept_id,
        ...this.form,
        userId: this.userInfo.id,
        userName: this.userInfo.real_name || this.userInfo.nick_name,
        recptStatus,
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$refs.gysForm.validate((valid) => {
            if (valid) {
              addcgList(parmas)
                .then((res) => {
                  console.log(res);
                  this.$message({
                    message: "保存成功",
                    type: "success",
                  });
                  this.headCancel();
                })
                .catch((error) => {});
            }
          });
        }
      });
    },
    // 获取编码
    getcgCode() {
      getcgCode({ organizationId: this.userInfo.dept_id }).then((res) => {
        // this.form.recptCode = res.data.data;
        this.$set(this.form, "recptCode", res.data.data || "");
      });
    },
    // 获取批次号
    getcgBatchCode() {
      getcgBatchCode({ organizationId: this.userInfo.dept_id }).then((res) => {
        // this.form.batchCode = res.data.data;
        this.$set(this.form, "batchCode", res.data.data || "");
      });
    },

    selectionChange(list) {
      this.dmItemInfo = list[0];
    },
    handleselectionChange() {
      this.form.itemName = this.dmItemInfo.itemName;
      this.form.itemId = this.dmItemInfo.id;
      this.form.itemCode = this.dmItemInfo.itemCode;
    },
    selectionRowChange(row) {
      this.form.itemName = row.itemName;
      this.form.itemId = row.id;
      this.form.itemCode = row.itemCode;
    },
    handleDocSuccess(res, file, fileList) {
      this.form.dmItemVendor.docUrl = file.response.data.link;
      this.form.dmItemVendor.docName = file.response.data.name;
    },
    handleQcSuccess(res, file, fileList) {
      this.form.dmItemVendor.qcUrl = file.response.data.link;
      this.form.dmItemVendor.qcName = file.response.data.name;
    },
    showFile(link) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL + "?url=" + encodeURIComponent(Base64.encode(link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    handlePreview(file) {
      console.log(file, "handlePreview");
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(file.url || file.response.data.link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  height: 100%;
}
.el-input-number.el-input-number--small {
  width: 100%;
}
</style>
